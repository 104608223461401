<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn btn-toolbar"
            :aria-label="$t(`cataloge.back`)"
            v-on:click="back()"
            v-if="catalog !== null || file !== null"
          >
            <BaseIcon name="left-arrow2" />
          </button>
          <h5 class="modal-title" id="staticBackdropLabel">
            {{ $t(`cataloge.key`, {expr: catalog === null ? "catalogs" : catalogName}) }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`addNewContactsModal.close`)"
            v-on:click="$emit('update:show', false)"
          ></button>
        </div>
        <div class="modal-body">
          <OneByOneTable
            v-if="catalog === null && file === null"
            v-model:rows="rows"
            v-model:items="items"
            :showAdd="false"
            :showEdit="true"
            :fix-height="true"
            v-on:edit="loadCatalog"
            v-on:delete="ShowDeleteCatalog"
            v-model:select-all="selectAll"
            type="products"
          />
          <iframe
            v-else-if="file !== null"
            :src="file"
            width="100%"
            height="500px"
          ></iframe>
          <DynamicTable
            v-else
            v-model:rows="catalogRows"
            v-model:items="catalogItems"
            v-model:select-all="selectAll"
            :fix-height="true"
            :show-order="false"
            :show-pin="false"
            v-on:loadMore="loadMore"
            type="products"
          />
        </div>
        <div class="modal-footer custom-input" v-if="catalog === null">
          <button
            class="btn btn-outline-primary"
            v-on:click="showImport = true"
          >
            {{ $t("cataloge.addNewCatalog") }}
          </button>
        </div>
        <div class="modal-footer custom-input" v-else>
          <button
            class="btn btn-outline-primary"
            v-on:click="showImport = true"
          >
            {{ $t("cataloge.importSettings") }}
          </button>
          <button class="btn btn-primary">{{ $t("cataloge.addToProducts") }}</button>
        </div>
      </div>
    </div>
  </div>
  <catalog-import
    v-model:show="showImport"
    :partner="partner"
    v-model:id="catalog"
    v-on:add="addNewCatalog"
  />
  <ConfirmModal
    ref="deleteModal"
    :title="$t(`cataloge.deleteCatalog`)"
    :text="`<ul><li>You will delete ${deleteObject?.name}<b></li><li>You can't undo this action</li></ul><p>Are you sure you want to delete?</p>`"
    :cancel-button="`No, keep ${deleteObject?.name}`"
    :ok-button="`Yes, delete ${deleteObject?.name}`"
    v-on:success="deleteCatalog"
  />
</template>

<script>
import CatalogImport from "./CatalogImport";
import http from "../../modules/http";
import OneByOneTable from "../lists/OneByOneTable";
import DynamicTable from "../lists/DynamicTable";
import BaseIcon from "../icons/BaseIcon";
import ConfirmModal from "./ConfirmModal";
import { defineAsyncComponent } from "vue";
import {useStore} from "vuex";
const bootstrap = require("bootstrap");

export default {
  name: "Catalogs",
  props: {
    modelValue: Object,
    show: Boolean,
    partner: Number,
  },
  emits: ["update:modelValue", "update:show"],
  data: function () {
    return {
      store: useStore(),
      file: null,
      showImport: false,
      selectAll: false,
      catalog: null,
      catalogName: null,
      deleteObject: {},
      rows: [
        {
          name: "Name",
          key: "name",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Language",
          key: "language",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Products number",
          key: "products",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Last import",
          key: "last_import",
          type: "date",
          show: true,
          editable: false,
        },
        {
          name: "Last import log",
          key: "catalog_last_log",
          type: "object",
          component: defineAsyncComponent(() =>
            import("../../components/lists/listItems/CatalogLastLog")
          ),
          show: true,
          editable: false,
        }
      ],
      items: undefined,
      catalogRows: [
        {
          name: "Image",
          key: "images",
          type: "image",
          show: true,
        },
        {
          name: "Name",
          key: "name",
          type: "string",
          show: true,
        },
        {
          name: "EAN",
          key: "ean",
          type: "string",
          show: true,
        },
        {
          name: "Color",
          key: "color",
          type: "string",
          show: true,
        },
        {
          name: "Size",
          key: "size",
          type: "string",
          show: true,
        },
        {
          name: "Price",
          key: "price",
          type: "price",
          show: true,
        },
      ],
      catalogItems: undefined,
      myModal: null,
      page: 1,
    };
  },
  mounted() {
    this.myModal = new bootstrap.Modal(this.$refs.modal, {
      backdrop: "static",
    });
    if (this.show) {
      this.load();
      this.myModal.show();
    } else {
      this.myModal.hide();
    }
    const _this = this;
    this.$refs.modal.addEventListener("hidden.bs.modal", function () {
      _this.$emit("update:show", false);
    });
  },
  watch: {
    show() {
      if (this.show) {
        this.load();
        this.myModal.show();
      } else {
        this.myModal.hide();
      }
    },
  },
  methods: {
    load() {
      http.fetch("/crm/partners/" + this.partner + "/catalogs").then((data) => {
        this.items = data.data;
      });
    },
    ShowDeleteCatalog(e) {
      this.deleteObject = e;
      this.$refs.deleteModal.showModal();
    },
    deleteCatalog() {
      http
        .fetch(
          "/crm/partners/" + this.partner + "/catalogs/" + this.deleteObject.id,
          {},
          false,
          "DELETE"
        )
        .then(() => {
          let i = this.items.indexOf(this.deleteObject);
          if (i > -1) {
            this.items.splice(i, 1);
          }
        });
    },
    back() {
      this.file = null;
      this.catalog = null;
      this.catalogItems = undefined;
    },
    loadCatalog(id, page = 1) {

      if (this.items.find((e) => e.id === id).products === 0) {
        this.file = `${process.env.VUE_APP_API}/download-catalog/${id}?_token=${this.store.state.token}`;

        this.catalogName = this.items.find((e) => e.id === id).name;
        return;
      }
      this.file = null;
      if (page === 1) {
        this.page = page;
        this.catalogItems = undefined;
        this.catalog = id;
        this.catalogName = this.items.find((e) => e.id === id).name;
      }
      http
        .fetch("/crm/catalogs/" + this.catalog + "/products?page=" + page)
        .then((data) => {
          if (page === 1) {
            this.catalogItems = data.data;
          } else {
            for (const row of data.data) {
              this.catalogItems.push(row);
            }
          }
        });
    },
    loadMore() {
      this.page++;
      this.loadCatalog(this.catalog, this.page);
    },
    addNewCatalog(catalog) {
      this.items.unshift(catalog);
    },
  },
  components: { ConfirmModal, BaseIcon, DynamicTable, OneByOneTable, CatalogImport },
};
</script>
